<template>
  <div
    v-if="show"
    id="uploadFilesCard"
    class="card card-custom grid-stack-item-content"
  >
    <b-modal
      v-show="files[bigImageIndex]"
      ref="bigImagePreview"
      :title="$t('general.preview')"
      hide-footer
    >
      <div v-if="files[bigImageIndex]" class="row">
        <div class="col-md-12 text-center">
          <div class="card-label" style="width: 100%">
            <h4 class="text-h4" style="overflow-wrap: break-word">
              {{ files[bigImageIndex].name }}
            </h4>
            <div
              v-if="files[bigImageIndex].size > 1000000"
              class="font-size-sm text-muted mt-2"
            >
              {{ (files[bigImageIndex].size / 1000 / 1000).toFixed(2) }} MB
            </div>
            <div v-else class="font-size-sm text-muted mt-2">
              {{ (files[bigImageIndex].size / 1000).toFixed(2) }} KB
            </div>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <img
            class="img-fluid img-thumbnail"
            :src="files[bigImageIndex].thumb"
          />
        </div>
      </div>
    </b-modal>
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("media.uploadFiles") }}</h3>
      </div>
      <div class="card-toolbar">
        <b-button
          v-if="files.length"
          variant="success"
          class="mr-2"
          @click="startUpload"
        >
          {{ $t("media.uploadFiles") }}
        </b-button>
        <b-form-radio-group
          v-if="files.length"
          id="viewMode"
          v-model="viewMode"
          class="mr-2"
          :options="options"
          name="radios-btn-default"
          button-variant="outline-primary"
          buttons
        ></b-form-radio-group>
        <b-button variant="secondary" @click="$emit('cancelUpload')">
          {{ $t("general.cancel") }}
        </b-button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />

    <div class="card-body">
      <div class="multiUploader">
        <div
          v-show="$refs.upload && $refs.upload.dropActive"
          class="drop-active"
        >
          <h3>{{ $t("media.dropFilesHere") }}</h3>
        </div>
        <file-upload
          ref="upload"
          :multiple="true"
          :maximum="maxFiles"
          :directory="false"
          :create-directory="false"
          :drop="true"
          :drop-directory="true"
          @input-file="inputFile"
        >
          <div class="card card-body">
            <h3>{{ $t("media.dropFilesHereOrClickToUpload") }}</h3>
          </div>
          <b-alert v-if="files.length >= maxFiles" show variant="danger">
            {{ $t("media.maxFileLimitReached", { limit: maxFiles }) }}
          </b-alert>
        </file-upload>
      </div>
      <div
        v-show="viewMode === 'grid'"
        v-if="files.length"
        class="card mt-2 bg-light p-5"
      >
        <div class="row">
          <div v-for="(entry, key) in files" :key="key" class="col-xl-2">
            <div class="card card-custom gutter-b card-stretch">
              <div class="card-header border-0 p-2" style="min-height: 100px">
                <div class="row" style="overflow: hidden !important">
                  <div class="col-md-10">
                    <div class="card-title font-weight-bolder">
                      <div class="card-label" style="width: 100%">
                        <span class="truncate">{{ entry.name }}</span>
                        <div
                          v-if="entry.size > 1000000"
                          class="font-size-sm text-muted mt-2"
                        >
                          {{ (entry.size / 1000 / 1000).toFixed(2) }} MB
                        </div>
                        <div v-else class="font-size-sm text-muted mt-2">
                          {{ (entry.size / 1000).toFixed(2) }} KB
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2 p-3">
                    <b-button
                      size="sm"
                      variant="danger"
                      block
                      @click="removeFile(key)"
                      ><i class="fal fa-trash"></i
                    ></b-button>
                  </div>
                </div>
              </div>
              <div class="card-body" style="position: relative">
                <div class="row justify-content-center flex-wrap py-3">
                  <div class="symbol justify-content-center symbol-150 py-0">
                    <div
                      v-if="entry.type.includes('image')"
                      style="cursor: zoom-in"
                      class="symbol-label symbol-label-profile"
                      :style="
                        'background-image: url(' +
                        entry.thumb +
                        '); background-color: #F3F6F9;'
                      "
                      @click="showBigImage(key)"
                    ></div>
                    <div v-else class="symbol-label">
                      <i
                        v-if="entry.type.includes('pdf')"
                        class="fal fa-file-pdf"
                        style="font-size: 50px"
                      ></i>
                      <i
                        v-else-if="entry.type.includes('zip')"
                        class="fal fa-file-archive"
                      ></i>
                      <i v-else class="fal fa-box-open-full"></i>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <input
                    v-model="filesDescriptions[key]"
                    type="text"
                    :placeholder="$t('media.description')"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="viewMode === 'table'" v-if="files.length" class="card mt-2">
        <table class="table datatable">
          <thead>
            <tr>
              <th></th>
              <th>{{ $t("media.fileName") }}</th>
              <th>{{ $t("media.description") }}</th>
              <th>{{ $t("media.fileSize") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(entry, key) in files" :key="key">
              <td>
                <div class="col-auto symbol symbol-50 py-0">
                  <div
                    v-if="entry.type.includes('image')"
                    class="symbol-label symbol-label-profile"
                    style="cursor: zoom-in"
                    :style="
                      'background-image: url(' +
                      entry.thumb +
                      '); background-color: #F3F6F9;'
                    "
                    @click="showBigImage(key)"
                  ></div>
                  <div v-else class="symbol-label">
                    <i
                      v-if="entry.type.includes('pdf')"
                      class="fal fa-file-pdf"
                    ></i>
                    <i
                      v-else-if="entry.type.includes('zip')"
                      class="fal fa-file-archive"
                    ></i>
                    <i v-else class="fal fa-box-open-full"></i>
                  </div>
                </div>
              </td>
              <td>{{ entry.name }}</td>
              <td>
                <input
                  v-model="filesDescriptions[key]"
                  type="text"
                  :placeholder="$t('media.description')"
                  class="form-control"
                />
              </td>
              <td v-if="entry.size > 1000000">
                {{ (entry.size / 1000 / 1000).toFixed(2) }} MB
              </td>
              <td v-else>{{ (entry.size / 1000).toFixed(2) }} KB</td>
              <td>
                <b-button size="sm" variant="danger" @click="removeFile(key)"
                  ><i class="fal fa-trash"></i
                ></b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.multiUploader .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.multiUploader .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.multiUploader .file-uploads {
  width: 100%;
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>

<script>
import FileUpload from "vue-upload-component";
import Media from "@/components/Admins/Media/media";
import Swal from "sweetalert2";

export default {
  name: "AdminsMediaMultipleFileUploader",
  components: {
    FileUpload
  },
  props: ["show"],
  data() {
    return {
      isBusy: false,
      viewMode: "grid",
      options: [
        { text: this.$t("media.gridView"), value: "grid" },
        { text: this.$t("media.tableView"), value: "table" }
      ],
      files: [],
      maxFiles: parseInt(process.env.VUE_APP_MAX_FILES_UPLOAD) || 20,
      filesDescriptions: [],
      bigImageIndex: false
    };
  },
  methods: {
    inputFile(file) {
      if (!file) {
        return;
      }
      let self = this;
      file.thumb = "";
      const reader = new FileReader();
      reader.onload = event => {
        file.thumb = event.target.result;
      };
      reader.readAsDataURL(file.file);
      setTimeout(function () {
        self.files.push(file);
      }, 200);
    },
    async startUpload() {
      this.isBusy = true;
      let mediaData = new FormData();
      this.files.forEach(entry => {
        mediaData.append("files[]", entry.file);
      });
      this.filesDescriptions.forEach(entry => {
        mediaData.append("description[]", entry);
      });
      await Media.store(mediaData)
        .then(response => {
          if (response.data.data.success) {
            this.$toast.fire({
              icon: "success",
              title: this.$t("media.filesUploaded")
            });
            this.isBusy = false;
            this.$emit("cancelUpload");
          }
        })
        .catch(error => {
          Swal.fire({
            title: this.$t("general.caution"),
            text: error.response.data.message,
            icon: "error"
          });
          this.isBusy = false;
        });
    },
    removeFile(index) {
      this.$refs.upload.remove(this.files[index]);
      this.$delete(this.files, index);
      this.$delete(this.filesDescriptions, index);
    },
    showBigImage(fileIndex) {
      this.bigImageIndex = fileIndex;
      this.$refs.bigImagePreview.show();
    }
  }
};
</script>
