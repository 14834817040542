<template>
  <div>
    <div v-if="userType === 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <MediaTable v-if="!showUpload" @uploadFiles="showUploadFiles">
        </MediaTable>
        <MultipleFileUpload
          v-if="showUpload"
          :show="showUpload"
          @cancelUpload="cancelUpload"
        >
        </MultipleFileUpload>
      </div>
    </div>
    <div v-if="userType !== 'admin'" class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <Denied></Denied>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import MediaTable from "@/components/Admins/Media/Table";
import MultipleFileUpload from "@/components/Admins/Media/MultipleFileUploaderFullScreen";
import Denied from "@/components/SYS/errors/Denied";

export default {
  name: "AdminsMediaIndex",
  components: {
    MediaTable,
    MultipleFileUpload,
    Denied
  },
  data() {
    return {
      userType: null,
      showUpload: false
    };
  },
  mounted() {
    this.userType = this.$store.getters.userType;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.adminMediaManagement"),
        route: ""
      }
    ]);
  },
  methods: {
    showUploadFiles() {
      this.showUpload = true;
    },
    cancelUpload() {
      this.showUpload = false;
    }
  }
};
</script>
