<template>
  <div id="mediaCard" class="card card-custom grid-stack-item-content">
    <div class="card-header">
      <div class="card-title">
        <h3 class="card-label">{{ $t("media.mediaManagement") }}</h3>
      </div>
      <div class="card-toolbar">
        <button class="btn btn-primary btn-hover" @click="$emit('uploadFiles')">
          <i class="fal fa-plus"></i>
          {{ $t("media.uploadFiles") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy" indeterminate color="primary" />
    <div class="card-body column">
      <TableWrapper :meta="meta" @reload-data="loadMedia">
        <template #filter>
          <b-button
            v-if="showDeleteButton"
            class="mr-1"
            type="button"
            variant="outline-danger"
            @click="deleteSelected"
          >
            {{ $t("media.deleteSelected") }}
          </b-button>
          <v-select
            ref="typeFilterSelector"
            v-model="typeFilter"
            style="max-width: 250px"
            class="form-control"
            :items="fileTypeOptions"
            item-text="name"
            item-value="id"
            :placeholder="$t('media.fileType')"
            multiple
            :menu-props="{ offsetY: true }"
            @blur="typeFilterChanged"
          />
        </template>
        <template #default>
          <b-table
            ref="mediaTable"
            :show-empty="true"
            :empty-text="$t('table.noRecords')"
            responsive
            :items="media"
            :fields="fields"
            class="dataTable table table-head-custom"
            select-mode="multi"
            selectable
            @row-selected="onRowSelected"
          >
            <template #head(checkbox)="data">
              <b-form-checkbox
                id="selectAllCheckbox"
                ref="selectAllCheckbox"
                v-model="selectAllCheckbox"
                @change="selectUnselectAll($event)"
              >
              </b-form-checkbox>
            </template>

            <template #cell(checkbox)="data">
              <b-form-checkbox
                v-if="data.rowSelected"
                :id="'checkbox-' + data.index"
                checked="true"
                @change="selectSingle($event, data.index)"
              >
              </b-form-checkbox>
              <b-form-checkbox
                v-if="!data.rowSelected"
                :id="'checkbox-' + data.index"
                @change="selectSingle($event, data.index)"
              >
              </b-form-checkbox>
            </template>

            <template #cell(actions)="data">
              <button
                class="btn btn-icon btn-light btn-sm mr-1"
                :class="!data.item.link ? 'disabled' : ''"
                :disabled="!data.item.link"
                @click="downloadMedia(data.item.uuid, data.item.name)"
              >
                <i class="fal fa-cloud-arrow-down"></i>
              </button>
              <b
                class="btn btn-icon btn-light btn-sm mr-1"
                @click="deleteMedia(data.item.id, data.item.name)"
              >
                <i class="fal fa-trash"></i>
              </b>
            </template>
            <template #cell(name)="data">
              <div class="row flex-wrap py-3">
                <div class="col-auto symbol symbol-50 py-0">
                  <div
                    v-if="data.item.type.includes('image')"
                    class="symbol-label symbol-label-profile"
                    :style="
                      data.item.link
                        ? 'background-image: url(' +
                          data.item.link +
                          '); background-color: #ffffff;'
                        : ''
                    "
                  ></div>
                  <div v-else class="symbol-label">
                    <i
                      v-if="data.item.type.includes('pdf')"
                      class="fal fa-file-pdf"
                    ></i>
                    <i
                      v-else-if="data.item.type.includes('zip')"
                      class="fal fa-file-archive"
                    ></i>
                    <i v-else class="fal fa-box-open-full"></i>
                  </div>
                </div>
                <div class="col-auto flex-grow-1 py-0">
                  <span class="d-block pt-4">{{ data.item.name }}</span>
                </div>
              </div>
            </template>
            <template #cell(file_size)="data">
              <div v-if="data.item.file_size > 1000000">
                {{ (data.item.file_size / 1000 / 1000).toFixed(2) }} MB
              </div>
              <div v-else>{{ (data.item.file_size / 1000).toFixed(2) }} KB</div>
            </template>
            <template #cell(user)="data">
              <router-link
                v-if="data.item.user && data.item.user.length > 1"
                :to="{
                  name: 'adminAccountUsersDetails',
                  params: { id: data.item.user.id }
                }"
              >
                #{{ data.item.user.id }} - {{ data.item.user.name }}
              </router-link>
            </template>
          </b-table>
        </template>
      </TableWrapper>
    </div>
  </div>
</template>

<style lang="scss">
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}

.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.swal2-title {
  display: block !important;
  overflow-wrap: break-word !important;
}
</style>

<script>
import Swal from "sweetalert2";
import { formatDate } from "@/components/Tools/modifiers";
import Media from "@/components/Admins/Media/media";
import TableWrapper from "@/components/Tools/TableWrapper";
import { mapGetters } from "vuex";

export default {
  name: "AdminsMediaTable",
  components: { TableWrapper },
  data() {
    return {
      selected: [],
      selectAllCheckbox: false,
      showDeleteButton: false,
      media: [],
      fields: [
        {
          key: "checkbox",
          label: "",
          sortable: false,
          thStyle: { width: "30px" }
        },
        {
          key: "id",
          label: this.$t("table.id"),
          sortable: true
        },
        {
          key: "uuid",
          label: this.$t("table.uuid"),
          sortable: true,
          thStyle: { width: "310px" }
        },
        {
          key: "name",
          label: this.$t("media.fileName"),
          sortable: true
        },
        {
          key: "description",
          label: this.$t("media.description"),
          sortable: true
        },
        {
          key: "type",
          label: this.$t("media.fileType"),
          sortable: true
        },
        {
          key: "file_size",
          label: this.$t("media.fileSize"),
          sortable: true
        },
        {
          key: "created_at",
          formatter: "formatDateAssigned",
          label: this.$t("table.createdAt"),
          sortable: true,
          thStyle: { width: "180px" }
        },
        {
          key: "user",
          label: this.$t("table.user"),
          sortable: true
        },
        {
          key: "actions",
          label: this.$t("table.actions"),
          sortable: false,
          thStyle: { width: "170px" }
        }
      ],
      meta: {},
      isBusy: false,
      fileTypeOptions: [
        {
          id: "image",
          name: this.$t("media.fileTypeImage")
        },
        {
          id: "text",
          name: this.$t("media.fileTypeText")
        },
        {
          id: "pdf",
          name: this.$t("media.fileTypePDF")
        }
      ],
      typeFilter: [],
      typeFilterTimeout: null,
      searchFilterTimeout: null,
      filters: {
        type: "",
        search: ""
      }
    };
  },
  computed: {
    ...mapGetters("route", ["requestParams", "cancelToken"])
  },
  watch: {
    selected(data) {
      this.showDeleteButton = data.length >= 1;
    },
    typeFilter() {
      let self = this;
      self.isBusy = true;
      clearTimeout(this.typeFilterTimeout);

      this.typeFilterTimeout = setTimeout(() => {
        self.$refs.typeFilterSelector.blur();
      }, 1000);
    }
  },
  mounted() {
    this.loadMedia();
    this.$root.$on("mediaTableRefresh", () => {
      this.refreshCard();
    });
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    loadMedia() {
      this.isBusy = true;
      let params = this.requestParams();
      let cancelToken = this.cancelToken();
      Media.getAll(params, this.filters, cancelToken)
        .then(response => {
          this.media = response.data.data;
          this.meta = response.data.meta;
          this.isBusy = false;
        })
        .catch(error => {
          this.$error(error);
          this.isBusy = this.axios.isCancel(error);
        });
    },
    downloadMedia(uuid, filename) {
      Media.download(uuid, filename);
    },
    formatDateAssigned(value) {
      return formatDate(value);
    },
    deleteMedia(id, name) {
      Swal.fire({
        title: this.$t("media.deleteConfirmation", { filename: name }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          Media.delete(id)
            .then(() => {
              this.$toast.fire({
                icon: "success",
                title: this.$t("media.fileDeleted")
              });
              if (this.media.length === 1 && this.page !== 1) {
                this.handlePageChange(this.page - 1);
              } else {
                this.loadMedia();
              }
            })
            .catch(error => {
              Swal.fire("Error", error.response.data.message, "error");
            });
        }
      });
    },
    selectSingle(checked, index) {
      if (checked) {
        this.$refs.mediaTable.selectRow(index);
      } else {
        this.$refs.mediaTable.unselectRow(index);
      }
    },
    selectUnselectAll(checked) {
      if (checked) {
        this.$refs.mediaTable.selectAllRows();
      } else {
        this.$refs.mediaTable.clearSelected();
      }
      this.selectAllCheckbox = checked;
    },
    deleteSelected() {
      let deleteArray = [];
      Object.keys(this.selected).forEach(key => {
        deleteArray.push(this.selected[key].id);
      });
      Swal.fire({
        title: this.$t("media.confirmBulkDelete"),
        text: this.$t("media.allSelectedRowsDelete", {
          count: deleteArray.length
        }),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: this.$t("general.cancel"),
        confirmButtonText: this.$t("general.delete")
      }).then(result => {
        if (result.isConfirmed) {
          Media.bulkDelete(deleteArray)
            .then(response => {
              if (response.data.data.success) {
                this.$toast.fire({
                  icon: "success",
                  title: this.$t("media.selectedRowsDeleted", {
                    count: response.data.data.removed
                  })
                });
              } else {
                this.$toast.fire({
                  icon: "warning",
                  title: this.$t("media.selectedRowsDeleted", {
                    count: response.data.data.removed
                  })
                });
              }

              const mediaLength = this.media.length - deleteArray.length;
              if (mediaLength === 0 && this.page !== 1) {
                this.handlePageChange(this.page - 1);
              } else {
                this.loadMedia();
              }
              this.selectAllCheckbox = false;
            })
            .catch(error => {
              Swal.fire(
                this.$t("general.caution"),
                error.response.data.data.message,
                "error"
              );
            });
        }
      });
    },
    typeFilterChanged() {
      let typeFilterIds = "";
      Object.keys(this.typeFilter).forEach(key => {
        typeFilterIds += this.typeFilter[key] + ",";
      });
      this.filters.type = typeFilterIds;
      this.loadMedia();
    },
    searchInput(event) {
      let self = this;
      self.isBusy = true;
      clearTimeout(this.typeFilterTimeout);
      this.typeFilterTimeout = setTimeout(() => {
        self.filters.search = event;
        self.loadMedia();
      }, 800);
    }
  }
};
</script>
