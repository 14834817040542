<template>
  <div
    class="bgi-size-cover bgi-position-center bgi-no-repeat p-5"
    :style="{
      backgroundImage: `url(${backgroundImage})`
    }"
  >
    <!-- begin:: Content -->
    <h1
      class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
      style="font-size: 150px"
    >
      {{ $t("error.accessDenied") }}
    </h1>
    <p class="font-size-h3 font-weight-light">
      {{ $t("error.noPermissions") }}
    </p>
    <!-- end:: Content -->
  </div>
</template>

<script>
export default {
  name: "SysErrorsDenied",
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg1.jpg";
    }
  },
  mounted() {}
};
</script>
