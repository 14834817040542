import ApiService from "@/core/services/api.service";
import axios from "axios";
import Swal from "sweetalert2";
import store from "@/core/services/store";

let url = process.env.VUE_APP_API_ADMIN;
let urlTenant = process.env.VUE_APP_API_TENANT;

class Media {
  get(id) {
    if (store.getters.userType === "admin") {
      return ApiService.get(url, "media/" + id);
    }
    return ApiService.get(urlTenant, "media/" + id);
  }
  getAll(params, filters = null, cancelToken = undefined) {
    let options = new URLSearchParams(params);
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        options.append("filters[" + key + "]", filters[key]);
      });
    }
    options = options.toString();
    let headers = {};
    if (cancelToken) {
      headers.cancelToken = cancelToken;
    }
    return ApiService.get(url, "media?" + options, headers);
  }
  async store(data) {
    if (store.getters.userType === "admin") {
      return await ApiService.post(url + "/media", data);
    }
    return await ApiService.post(urlTenant + "/media", data);
  }
  async storeTenant(data) {
    return await ApiService.post(urlTenant + "/media", data);
  }
  delete(id) {
    if (store.getters.userType === "admin") {
      return ApiService.delete(url + "/media/" + id);
    }
    return ApiService.delete(urlTenant + "/media/" + id);
  }
  bulkDelete(ids) {
    let sendData = {
      ids: ids
    };
    return ApiService.post(url + "/media/deleteBulk", sendData);
  }
  download(uuid, filename) {
    axios({
      url: url + "/media/download/" + uuid,
      method: "GET",
      responseType: "blob"
    })
      .then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch(error => {
        Swal.fire("Error", error.response.data.message, "error");
      });
  }
}
export default new Media();
