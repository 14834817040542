export function formatDate(date, format = "DD.MM.YYYY HH:mm:ss", inputFormat) {
  let moment = require("moment");

  if (!date || !moment(date).isValid()) return false;

  return (inputFormat ? moment(date, inputFormat) : moment(date)).format(
    format
  );
}

export function cutText(text = "", keepLength = 7, keepEnd = false) {
  let textLength = text.length;
  let cutLength = textLength - keepLength;
  if (cutLength >= textLength) return text;

  if (keepEnd) {
    return "..." + text.slice(cutLength);
  } else {
    return text.slice(0, keepLength) + "...";
  }
}
